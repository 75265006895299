import { createTheme } from '@mui/material/styles';

// Define your color palette
const primaryColor = '#FCDC2A';
const secondaryColor = '#87A922';
const backgroundLight = '#F7F6BB';
const textColor = '#114232';
const white = '#ffffff';

// Define your fonts
const primaryFont = "poppins";
const secondaryFont = "playwrite";

// Create the theme
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: backgroundLight,
      paper: white,
    },
    text: {
      primary: textColor,
      secondary: secondaryColor,
    },
  },
  typography: {
    fontFamily: primaryFont,
    h1: {
      fontFamily: secondaryFont,
      fontWeight: 700,
      fontSize: '2.5rem',
      color: primaryColor,
    },
    h2: {
      fontFamily: secondaryFont,
      fontWeight: 600,
      fontSize: '2rem',
      color: primaryColor,
    },
    body1: {
      fontFamily: primaryFont,
      fontSize: '1rem',
      color: textColor,
    },
    body2: {
      fontFamily: primaryFont,
      fontSize: '0.875rem',
      color: textColor,
    },
    button: {
      fontFamily: primaryFont,
      fontWeight: 600,
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: primaryFont,
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColor,
        },
      },
    },
  },
});

export default theme;
