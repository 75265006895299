import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import logoImg from '../../Images/Logo_MWD.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const pages = ['Home', 'About', 'Services', 'Contact'];

const MenuBar: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size

  // Handler for opening/closing the mobile menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
   
  }, []);

  return (
    <AppBar
    position="fixed"
    sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', boxShadow: 'none', zIndex: 1200}} // Transparent background and no shadow
  >
    <Toolbar>
      {/* Left-aligned Logo */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <img
          src={logoImg}// Replace with your logo path
          alt="Logo"
          style={{ height: '40px', marginRight: '16px' }}
        />
        <Typography variant="h6" component="div" sx={{color: 'white'}} >
          Mango Web Designs
        </Typography>
      </Box>

      {/* Right-aligned Menu - show buttons for desktop, menu icon for mobile */}
      {isMobile ? (
        <>
          {/* Mobile: Show menu icon */}
          <IconButton
            edge="end"
            onClick={handleMenuOpen}
            sx={{
              color: 'white'
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile: Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem  onClick={() => navigate('/')}>Home</MenuItem>
            <MenuItem onClick={() => navigate('/services')}>Services</MenuItem>
            <MenuItem onClick={() => navigate('/contactus')}>Contact</MenuItem>
          </Menu>
        </>
      ) : (
        <Box sx={{ display: 'flex', padding: '2vh' }}>
          {/* Desktop: Show buttons */}
          <Button sx={{color: 'white'}} onClick={() => navigate('/')}>Home</Button>
          <Button sx={{color: 'white'}} onClick={() => navigate('/services')}>Services</Button>
          <Button sx={{color: 'white'}} onClick={() => navigate('/contactus')}>Contact</Button>
        </Box>
      )}
    </Toolbar>
  </AppBar>
  );
};

export default MenuBar;