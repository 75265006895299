import React, { useEffect } from "react";
import { Stack, Toolbar, Typography, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuBar from "../MenuBar";
import mangobg from "../../../Images/mangoImg1.jpg";
import eventImg from "../../../Images/eventTicket.png";
import shopImg from '../../../Images/designElement.gif'
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import useGoogleAnalytics from "../../../Services/GoogleAnalytics/useGoogleAnalytics";
import InfoBlock from "../../InfoBlock/InfoBlock";
import { Helmet } from "react-helmet";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  // Google Analytics
  useGoogleAnalytics();
  const { trackEvent } = useGoogleAnalytics();

  // HADNLE BUTTON CLICKS
  const handelEnquiryButton = () => {
    navigate("/contactus");
    trackEvent("Button", "Click", "Home Page Enquiry Button");
  };

  useEffect(() => {
    document.title = "Home Page";
  }, []);

  return (
    <Box>
      <Helmet>
        <meta name="description" content="Home page of Mango Web Designs" />
        <meta name="keywords" content="home, website, service, designs" />
      </Helmet>
      {/* Menu bar sticking to the top */}
      <MenuBar />

      <Box
        sx={{
          height: "100vh",
          backgroundImage: `url(${mangobg})`, // Replace with your image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          flexDirection: "column",
        }}
      >
        {/* Black gradient overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)", // Black gradient with 50% opacity
          }}
        />

        {/* Content of the home page */}
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            color: "white",
            padding: isMobile ? 7 : 15,
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "white",
                fontFamily: "brittany",
                width: "60vw",
                textAlign: "left",
                fontSize: isMobile ? "6vh" : "18vh",
                paddingY: 3,
              }}
            >
              Mango Web Desings
            </Typography>
            <Typography
              sx={{
                color: "white",

                width: "60vw",
                textAlign: "left",
                fontFamily: "newpose",
                fontSize: isMobile ? "4vh" : "6vh",
                paddingY: 3,
              }}
            >
              Custom Website Builders
            </Typography>
            <Box paddingY={3}>
              <Button onClick={handelEnquiryButton} variant="contained">
                Get a Free Quote
              </Button>
            </Box>
          </Stack>
        </Box>
        {/* Additional Content Below */}
      </Box>
      <Stack direction={isMobile ? "column" : "row"}>
        <Box sx={{ height: "100vh",
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
         }}>
          <InfoBlock
            left={true}
            heading="Bring your Event to life"
            body="Websites designed to reach your audience wherever they are. Ticketing, Audience Analytics & Management"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: isMobile?'50vh':"100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Adjust to desired width
              height: "auto", // Keep aspect ratio
              maxWidth: "500px", // Limit the width if needed
              paddingBottom: isMobile?9:10
            }}
            alt="Descriptive Alt Text"
            src={eventImg} // Replace with your image URL
          />
        </Box>
      </Stack>
      <Stack direction={isMobile ? "column-reverse" : "row"}>
      <Box
          sx={{
            width: '100%',
            height: isMobile?'50vh':"100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Adjust to desired width
              height: "auto", // Keep aspect ratio
              maxWidth: "400px", // Limit the width if needed
              paddingBottom: isMobile?9:10
            }}
            alt="Descriptive Alt Text"
            src={shopImg} // Replace with your image URL
          />
        </Box>
        <Box sx={{ height: "100vh", width: isMobile ? "100vw" : "50vw",
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
        }}>
          <InfoBlock
            left={false}
            heading="Sell your products"
            body="Websites designed to bring your products to the virtual market. Smooth and Rich UI to keep your customers shopping"
          />
        </Box>
      </Stack>

      <Footer />
    </Box>
  );
};

export default Home;
